<template>
  <div>
    <label
      class="upload-container mb-4"
      :class="{ 'has-error': form.$getError('photos') }"
      @drop.prevent="handleUploadPhoto($event)"
      @dragover.prevent
    >
      <v-img
        :src="require('@/assets/icons/file-upload-placeholder.svg')"
        alt="File Upload Placeholder"
        width="80px"
        height="80px"
      />

      <div class="upload-label mt-3">
        <span variant="primary">
          Upload photo
        </span>
      </div>

      <span>Or drag them in</span>
      <span v-if="form.$getError('photos')" class="error--text">{{
        form.$getError('photos')[0] === 'The thumb nail must be an image.'
          ? 'The thumbnail must be an image.'
          : form.$getError('photos')[0]
      }}</span>

      <v-file-input
        ref="filePicker"
        :multiple="multiple"
        accept="image/*"
        aria-invalid="input-photos-feedback"
        class="d-none"
        @change="handleUploadPhoto($event)"
        :key="componentKey"
      />
    </label>

    <v-row v-if="multiple">
      <v-col
        v-for="(photo, index) in form.photos"
        :key="index"
        cols="4"
        class="image-container"
      >
        <v-img :src="photo.url" height="150" contain class="grey darken-4" />
        <v-btn
          fab
          class="radius__button text-none remove-img error"
          x-small
          depressed
          @click="removeImage(index)"
        >
          <v-icon small color="white">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        v-for="(photo, index) in form.photos"
        :key="index"
        cols="12"
        class="image-container"
      >
        <v-img :src="photo.url" height="auto" contain class="grey darken-4" />
        <v-btn
          fab
          class="radius__button text-none remove-img error"
          x-small
          depressed
          @click="removeImage(index)"
        >
          <v-icon small color="white">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiClose } from '@mdi/js'

export default {
  props: {
    form: {
      type: [Object, Array],
      require: true
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      removedImage: [],
      componentKey: 0,
      icons: {
        mdiClose
      }
    }
  },
  computed: {
    isUpdating() {
      return this.$route.name.includes('edit')
    }
  },
  methods: {
    async handleUploadPhoto(e) {
      const files = (e.dataTransfer && Array.from(e.dataTransfer.files)) || e

      if (this.isUpdating) {
        this.handleTempUpload(files)
        this.form.$clearError('photos')
        return
      }
      const photos = await Promise.all(
        files.map(async file => {
          return {
            file,
            url: await this.getImageURI(file)
          }
        })
      )

      photos.forEach(photo => {
        // this.form.photos.push(photo))
        this.form.photos = [photo]
      })

      this.form.$clearError('photos')
      this.componentKey++
    },
    getImageURI(photo) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(photo)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    removeImage(index) {
      this.removedImage.push(this.form.photos[index])
      this.form.photos.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-container {
  width: 100%;
  height: 312px;
  border-radius: 6px;
  border: dashed 2px #2c66d54d;
  background-color: #2c66d50a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  cursor: pointer;
  user-select: none;

  &.has-error {
    border: dashed 2px #a31f314d;
  }

  .v-image {
    flex-grow: 0;
  }

  .upload-label span {
    box-shadow: 0 12px 20px rgba(44, 102, 213, 0.4);
    border-color: #0062cc;
    background-color: #0062cc;
    color: #ffffff;
    padding: 4px 32px;
    border-radius: 8px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    margin-bottom: 12px;
  }
}

.remove-img {
  position: absolute;
  right: 0;
  top: 0;
}

.image-container {
  position: relative;
}
</style>
