const constants = {
  optionTypes: {
    INPUT: 'Input',
    OPTION: 'Option',
    CALENDAR: 'Calendar'
  },
  onboardingMenu: {
    EDIT: 'Edit',
    DELETE: 'Delete'
  },
  programMenu: {
    EDIT: 'Edit',
    DELETE: 'Delete'
  }
}

export function optionTypes() {
  return constants.optionTypes
}

export function onboardingMenu() {
  return constants.onboardingMenu
}

export function programMenu() {
  return constants.onboardingMenu
}
